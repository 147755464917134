<template>
  <div class="inventory-detail">
    <KTCodePreview>
      <template v-slot:preview>
        <div>
          <b-card
            no-body
            class="full-width"
            style="background-color: rgb(243, 246, 249)"
          >
            <b-row class="mt-5 px-2 py-0">
              <b-col>
                <b class="font-weight-bolder">{{ productCode }} - {{ productName }}</b>
              </b-col>
              <div class="justify-content-end">
                <b-col>
                  <b class="font-weight-bolder">Tồn kho: {{ totalQuantity }}</b>
                </b-col>
              </div>
            </b-row>
            <!--begin: Wizard-->
            <div
              class="wizard wizard-4 mt-5"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Chi tiết</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Nav -->

              <!--begin: Wizard Body-->
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row py-8 px-8 py-lg-15 px-lg-10">
                    <div class="col-xl-12">
                      <!--begin: Wizard Form-->
                      <form
                        class="form mt-0"
                        id="kt_form"
                      >
                        <!--begin: Chi tiết-->
                        <div
                          class="pb-5 full-width"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          <b-row>
                            <b-col>
                              <b-form>
                                <b-form-group
                                  id="input-group-id"
                                  label-for="input-name"
                                >
                                  <Autosuggest
                                    :model="searchStock"
                                    :suggestions="filteredOptions"
                                    :placeholder="'kho'"
                                    :limit="10"
                                    @select="onSelected"
                                    @change="onInputChange"
                                    suggestionName="suggestionName"
                                  />
                                </b-form-group>
                              </b-form>
                            </b-col>
                            <b-col>
                              <b-button
                                style="fontweight: 600; width: 70px"
                                variant="primary"
                                size="sm"
                                @click="getProductStock"
                              >Lọc</b-button>
                            </b-col>
                            <b-col></b-col>
                            <b-col></b-col>
                          </b-row>

                          <b-table
                            :items="detailItems"
                            :fields="detailFields"
                            bordered
                            hover
                            :busy="onLoadingDetail"
                          >
                            <template v-slot:table-busy>
                              <vcl-table
                                :speed="5"
                                :animate="true"
                                :columns="9"
                              ></vcl-table>
                            </template>
                            <template v-slot:cell(quantity)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.quantity"></span>
                              </div>
                            </template>
                            <template v-slot:cell(inStockQuantity)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.inStockQuantity"></span>
                              </div>
                            </template>
                            <template v-slot:cell(deliveryQuantity)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.deliveryQuantity"></span>
                              </div>
                            </template>
                            <template v-slot:cell(transferQuantity)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.transferQuantity"></span>
                              </div>
                            </template>
                            <template v-slot:cell(holdingQuantity)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.holdingQuantity"></span>
                              </div>
                            </template>
                            <template v-slot:cell(warrantyQuantity)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.warrantyQuantity"></span>
                              </div>
                            </template>
                            <template v-slot:cell(ctb)="row">
                              <div style="text-align: end">
                                <span v-text="row.item.ctb"></span>
                              </div>
                            </template>
                          </b-table>
                        </div>
                        <!--end: Chi tiết-->

                        <!--begin: Lịch sử-->
                        <div
                          class="pb-5 full-width"
                          data-wizard-type="step-content"
                        >
                          <b-row>
                            <b-col md="3">
                              <b-form>
                                <b-form-group
                                  id="input-group-id"
                                  label-for="input-name"
                                >
                                  <Autosuggest
                                    :model="selectedHistory"
                                    :suggestions="filteredOptions"
                                    :placeholder="'kho'"
                                    :limit="10"
                                    @select="onSelectedHistory"
                                    @change="onInputChangeHistory"
                                    suggestionName="suggestionName"
                                  />
                                </b-form-group>
                              </b-form>
                            </b-col>
                            <b-col md="2">
                              <date-picker
                                placeholder="Từ ngày"
                                class="form-control-sm"
                                :config="dpConfigs.start"
                                v-model="dpForm.startDate"
                              ></date-picker>
                            </b-col>
                            <b-col md="2">
                              <date-picker
                                placeholder="Đến ngày"
                                class="form-control-sm"
                                :config="dpConfigs.end"
                                v-model="dpForm.endDate"
                              ></date-picker>
                            </b-col>
                            <b-col>
                              <b-button
                                style="fontweight: 600; width: 70px"
                                variant="primary"
                                size="sm"
                                @click="onFilter"
                              >Lọc</b-button>
                            </b-col>
                          </b-row>

                          <b-table
                            :items="historyItems"
                            :fields="historyFields"
                            bordered
                            hover
                            :busy="onLoadingHistory"
                          >
                            <template v-slot:table-busy>
                              <vcl-table
                                :speed="5"
                                :animate="true"
                                :columns="6"
                              ></vcl-table>
                            </template>
                            <template v-slot:cell(code)="row">
                              <div
                                class="productCode"
                                @click="editItem(row.item)"
                                style="cursor: pointer"
                                v-clipboard:copy="row.item.code"
                              >
                                <span v-text="row.item.code"></span>
                              </div>
                            </template>
                          </b-table>
                          <b-row>
                            <b-col>
                              <p
                                class="mt-3 text-dark"
                                style="font-weight: 500"
                              >
                                Tổng:
                                {{ convertPrice(totalItems) }}
                              </p>
                            </b-col>
                            <b-col>
                              <b-pagination-nav
                                v-model="page"
                                class="custom-pagination"
                                v-show="totalPages >= 2"
                                :link-gen="linkGen"
                                :number-of-pages="totalPages"
                                use-router
                                @change="getProductStockHistory"
                                align="right"
                                first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                                prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                                last-class="page-item-last btn btn-icon btn-sm my-1 "
                                page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                              >
                                <template v-slot:first-text>
                                  <span>
                                    <i class="
                                        ki ki-bold-double-arrow-back
                                        icon-xs
                                      "></i>
                                  </span>
                                </template>

                                <template v-slot:prev-text>
                                  <i class="ki ki-bold-arrow-back icon-xs"></i>
                                </template>

                                <template v-slot:next-text>
                                  <i class="ki ki-bold-arrow-next icon-xs"></i>
                                </template>

                                <template v-slot:last-text>
                                  <span class="text-info">
                                    <i class="
                                        ki ki-bold-double-arrow-next
                                        icon-xs
                                      "></i>
                                  </span>
                                </template>
                              </b-pagination-nav>
                            </b-col>
                          </b-row>
                        </div>
                        <!--end: Lịch sử-->
                      </form>
                      <!--end: Wizard Form-->
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Bpdy-->
            </div>
            <!--end: Wizard-->
          </b-card>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import { makeToastSuccess } from '@/utils/common';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

const WARRANTY_HISTORY = 2;
export default {
  data() {
    return {
      detailFields: [
        {
          thStyle: { textAlign: 'center' },
          key: 'count',
          label: 'STT',
          tdClass: 'sttClass',
          thClass: 'sttClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'store',
          label: 'Kho',
          tdClass: 'storeClass',
          thClass: 'storeClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'quantity',
          label: 'SL',
          tdClass: 'quantityClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'inStockQuantity',
          label: 'Tồn trong kho',
          tdClass: 'quantityClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'deliveryQuantity',
          label: 'Đang giao',
          tdClass: 'quantityClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'transferQuantity',
          label: 'Đang chuyển',
          tdClass: 'quantityClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'holdingQuantity',
          label: 'Đang giữ hàng',
          tdClass: 'quantityClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'warrantyQuantity',
          label: 'Đang bảo hành',
          tdClass: 'quantityClass',
        },
      ],
      historyFields: [
        {
          key: 'count',
          label: 'STT',
        },
        {
          key: 'store',
          label: 'Kho',
        },
        {
          key: 'headNum',
          label: 'SL đầu',
          tdClass: 'text-right',
        },
        {
          key: 'afterNum',
          label: 'SL sau',
          tdClass: 'text-right',
        },
        {
          key: 'headWarrantyNum',
          label: 'SL BH đầu',
          tdClass: 'text-right',
        },
        {
          key: 'afterWarrantyNum',
          label: 'SL BH sau',
          tdClass: 'text-right',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          tdClass: 'text-left',
        },
        {
          key: 'action',
          label: 'Hành động',
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
        },
      ],
      detailItems: [],
      historyItems: [],
      productId: null,
      productCode: '',
      productName: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStock: '',
      searchStockHistory: '',
      listStore: [],
      totalQuantity: 0,
      selectedHistory: '',
      onLoadingDetail: false,
      onLoadingHistory: false,
      selectedStore: null,
      selectedStoreHistory: null,
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      totalPages: 2,
      page: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}&id=${this.productId}`;
      },
      totalItems: 0,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm', route: '/products' },
      { title: 'Chi tiết tồn kho' },
    ]);
    this.productId = this.$route.query.id;
    this.getProductStock();
    this.getProductStockHistory();
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on('beforeNext', function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      // alert(wizardObj.currentStep);
      // alert(wizardObj.totalSteps);
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
    },
    onSelectedHistory(option) {
      this.searchStockHistory = option.item.name;
      this.selectedStoreHistory = option.item.id;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    onInputChangeHistory(text) {
      if (!text) {
        text = '';
      }
      this.searchStockHistory = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getProductStock: function () {
      if (!this.searchStock || this.searchStock.trim() === '') {
        this.selectedStore = null;
      }

      const param = {
        productId: this.productId,
        storeId: this.selectedStore,
      };
      const paramQuery = {
        params: param,
      };

      this.onLoadingDetail = true;
      ApiService.query('productStock', paramQuery)
        .then(({ data }) => {
          if (data.status === 1) {
            this.detailItems = [];
            this.productCode = data.data.productCode;
            this.productName = data.data.productName;
            this.totalQuantity = data.data.totalStock;
            let count = 1;
            data.data.listStockDetail.forEach((element) => {
              let ctb =
                element.quantity -
                element.holdingQuantity -
                element.warrantyQuantity;
              if (ctb < 0) {
                ctb = 0;
              }
              let item = {
                count: count,
                store: element.storeName,
                quantity: element.quantity !== 0 ? element.quantity : '',
                inStockQuantity:
                  element.inStockQuantity !== 0 ? element.inStockQuantity : '',
                deliveryQuantity:
                  element.deliveryQuantity !== 0
                    ? element.deliveryQuantity
                    : '',
                transferQuantity:
                  element.transferQuantity !== 0
                    ? element.transferQuantity
                    : '',
                holdingQuantity:
                  element.holdingQuantity !== 0 ? element.holdingQuantity : '',
                warrantyQuantity:
                  element.warrantyQuantity !== 0
                    ? element.warrantyQuantity
                    : '',
                ctb: ctb !== 0 ? ctb : '',
              };
              this.detailItems.push(item);
              count++;
            });
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
          this.onLoadingDetail = false;
        })
        .catch(({ response }) => {
          this.onLoadingDetail = false;
          this.makeToastFaile(response.$error);
        });
    },
    getProductStockHistory: function () {
      if (!this.searchStockHistory || this.searchStockHistory.trim() === '') {
        this.selectedStoreHistory = null;
      }

      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const param = {
        productId: this.productId,
        storeId: this.selectedStoreHistory,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : '',
        page: this.page,
        limit: 30,
      };

      const paramQuery = {
        params: param,
      };
      this.onLoadingHistory = true;

      ApiService.query('productStock/history', paramQuery)
        .then(({ data }) => {
          if (data.status === 1) {
            this.totalItems = data.data.totalRow;
            this.totalPages = data.data.totalPage;
            this.historyItems = data.data.data.map((element, index) => {
              const isWarrantyHistory =
                element.historyType === WARRANTY_HISTORY;
              return {
                count: index + 1,
                store: element.storeName,
                headNum: isWarrantyHistory ? '' : element.beforeQuantity,
                afterNum: isWarrantyHistory ? '' : element.afterQuantity,
                headWarrantyNum: isWarrantyHistory
                  ? element.beforeQuantity
                  : '',
                afterWarrantyNum: isWarrantyHistory
                  ? element.afterQuantity
                  : '',
                historyType: element.historyType,
                code: element.stockSlipCode,
                stockSlipId: element.stockSlipId,
                type: element.stockSlipType,
                createdBy: element.createdBy,
                createdAt: moment(String(element.createdAt)).format(
                  'DD/MM/YYYY HH:mm:ss',
                ),
                action: element.action,
              };
            });
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
          this.onLoadingHistory = false;
        })
        .catch(({ response }) => {
          this.onLoadingHistory = false;
          this.makeToastFaile(response.$error);
        });
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStore = stores;
        stores.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [...this.options[0].data];
      });
    },
    editItem: function (item) {
      if (item.type === 1) {
        const { href } = this.$router.resolve({
          name: 'update-stock',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 2) {
        const { href } = this.$router.resolve({
          name: 'update-export-stock',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 3) {
        const { href } = this.$router.resolve({
          name: 'update-transfer-stock',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 5) {
        const { href } = this.$router.resolve({
          name: 'list-require-stock',
          query: { code: item.code },
        });
        window.open(href, '_blank');
      } else if (item.type === 12) {
        const { href } = this.$router.resolve({
          name: 'update-transfer-stock-to-nhanh',
          query: { id: item.stockSlipId },
        });
        window.open(href, '_blank');
      } else if (item.type === 13) {
        const { href } = this.$router.resolve({
          name: 'list-require-stock-to-nhanh',
          query: { code: item.code },
        });
        window.open(href, '_blank');
      } else if (item.type === 15) {
        const { href } = this.$router.resolve({
          name: 'list-import-warranty',
          query: { code: item.code },
        });
        window.open(href, '_blank');
      } else if (item.type === 16) {
        const { href } = this.$router.resolve({
          name: 'list-export-warranty',
          query: { code: item.code },
        });
        window.open(href, '_blank');
      } else {
        makeToastSuccess(`Đã copy mã: ${item.code}!`);
      }
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'inventory-detail',
        query: {
          id: this.productId,
        },
      });
      this.getProductStockHistory();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';

.inventory-detail {
  .full-width .nav-tabs .nav-item {
    margin-bottom: -1px;
    flex-grow: 1 !important;
    text-align: center !important;
  }

  .sttClass {
    width: 3%;
    text-align: center;
  }
  .storeClass {
    width: 17%;
  }
  .quantityClass {
    width: 8.5%;
  }
  .unitPriceClass {
    width: 13.5%;
  }
  .totalPriceClass {
    width: 14.5%;
  }
  .productCode:hover {
    text-decoration: underline;
  }
}
</style>